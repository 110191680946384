import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener, Inject,
  Input,
  OnInit,
  Output,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import { BehaviorSubject, EMPTY, Observable, of } from 'rxjs';
import { catchError, finalize, tap } from 'rxjs/operators';
import { UntypedFormArray, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { NzUploadChangeParam, NzUploadFile } from 'ng-zorro-antd/upload';
import LanguageService from '@shared/language/language.service';
import { NotificationService } from '@shared/services/notification.service';
import ReportDto, { ReportType } from '../../../dto/report.dto';
import { AuthService } from '../../../../auth/auth.service';
import ScheduleApiService from '../../../schedule-api.service';
import ScheduleCalendarService from '../../schedule-calendar.service';
import { NZ_MODAL_DATA } from 'ng-zorro-antd/modal';

@Component({
  selector: 'math-report-modal',
  templateUrl: './report-modal.component.html',
  styleUrls: ['./report-modal.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ReportModalComponent implements OnInit {

  @Input() firstLessonId: string;
  @Input() reportId: string;
  @Input() editMode: boolean;
  @Input() studentId: string;

  @Output() onSuccess = new EventEmitter<void>();
  @Output() onCancel = new EventEmitter<void>();

  private teacherId: string;

  public isLoading = false;

  public reportType: typeof ReportType= ReportType;

  public reportData$: Observable<Partial<ReportDto>>;
  public loadingError$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  public reportForm: UntypedFormGroup = new UntypedFormGroup({
    knowledge: new UntypedFormControl('', [Validators.required, Validators.minLength(100)]),
    gaps: new UntypedFormControl('', [Validators.required, Validators.minLength(100)]),
    conclusion: new UntypedFormControl('', [Validators.required, Validators.minLength(100)]),
    recommendation: new UntypedFormControl(''),
    completionPercentage: new UntypedFormControl('', Validators.maxLength(255)),
    type: new UntypedFormControl(''),
    links: new UntypedFormArray([], Validators.maxLength(5)),
  });

  @ViewChild('linkInputElement', { static: false }) linkInputElement?: ElementRef;
  public linkInputVisible = false;
  public linkInputValue = '';

  @HostListener('document:keydown.control.v')
  private onPasteByCtrlV() {
    this.handleClickPaste();
  }

  @HostListener('document:keydown.control.м')
  private onPasteByCtrlVCyrillic() {
    this.handleClickPaste();
  }

  @HostListener('document:keydown.meta.v')
  private onPasteByCommandV() {
    this.handleClickPaste();
  }

  @HostListener('document:keydown.meta.м')
  private onPasteByCommandVCyrillic() {
    this.handleClickPaste();
  }

  private readonly supportedFileTypes: string[] = ['image/png', 'image/jpeg', 'image/jpg', 'image/webp'];
  private readonly oneKb: number = 1000;
  public fileList: NzUploadFile[] = [];
  private filesIdsToRemove: number[] = [];
  public imagePreview: string | undefined = '';
  public isImagePreviewVisible = false;

  constructor(
    private readonly scheduleApiService: ScheduleApiService,
    private readonly languageService: LanguageService,
    private readonly notificationService: NotificationService,
    private readonly translateService: TranslateService,
    private readonly authService: AuthService,
    private readonly scheduleCalendarService: ScheduleCalendarService,
  ) { }

  public async handleClickPaste(): Promise<void> {
    const clipboardItems = await navigator.clipboard.read();

    if (!clipboardItems.length) {
      this.notificationService.showInfoMessage(this.translateService.instant('calendar.report.upload-err-by-empty-buffer'));
      return;
    }

    const [item] = clipboardItems;

    if (item.types.find((type) => type === 'text/plain')) {
      return;
    }

    const foundImageMimeType = item.types.find((type: string) => this.supportedFileTypes.includes(type));

    if (foundImageMimeType) {
      const blob = await item.getType(foundImageMimeType);
      const uid = crypto.randomUUID();
      const file = new File([blob], uid, {
        type: foundImageMimeType,
      });

      (file as any).uid = uid;

      this.beforeImageUpload(file as any);
    } else {
      this.notificationService.showInfoMessage(this.translateService.instant('calendar.report.upload-err-by-type-buffer'));
    }
  }

  public ngOnInit(): void {
    this.loadingError$.next(false);

    if (!this.editMode) {
      const type = this.firstLessonId ? ReportType.DIAGNOSTIC : ReportType.REGULAR;
      this.reportData$ = of({
        createdAt: new Date().toISOString(),
        type,
      });

      if (type === ReportType.DIAGNOSTIC) {
        this.reportForm.controls['completionPercentage'].setValidators(Validators.required);
        this.reportForm.controls['recommendation'].setValidators(Validators.required);
        this.reportForm.controls['recommendation'].setValue(
          this.translateService.instant('calendar.report.recommendation-diagnostic-default'),
        );
        this.reportForm.controls['gaps'].setValue(
          this.translateService.instant('calendar.report.gaps-diagnostic-default'),
        );
        this.reportForm.controls['gaps'].markAsDirty();
      } else {
        this.reportForm.controls['conclusion'].setValue(
          this.translateService.instant('calendar.report.conclusion-regular-default'),
        );
        this.reportForm.controls['conclusion'].markAsDirty();

        this.reportForm.controls['gaps'].setValue(
          this.translateService.instant('calendar.report.gaps-regular-default'),
        );
        this.reportForm.controls['gaps'].markAsDirty();
      }

      return;
    }

    this.reportData$ = this.scheduleApiService.getReportById(this.reportId).pipe(
      tap(report => {
        this.teacherId = report.teacherId;

        this.reportForm.patchValue({
          knowledge: report.knowledge,
          gaps: report.gaps,
          conclusion: report.conclusion,
          recommendation: report.recommendation,
          completionPercentage: report.completionPercentage,
          type: report.type,
        });

        if (report.type === ReportType.DIAGNOSTIC) {
          this.reportForm.controls['completionPercentage'].setValidators(Validators.required);
          this.reportForm.controls['recommendation'].setValidators(Validators.required);
        }

        this.reportForm.markAllAsTouched();

        if (report.links?.length) {
          for (const link of report.links) {
            this.links.push(new UntypedFormControl(link));
          }
        }

        this.fileList.push(...report.materials.map(el => ({
          id: el.id,
          uid: el.externalId,
          name: `${el.externalId}.${el.extension}`,
          url: el.src,
          currentlyUploaded: true,
        })));
      }),
      catchError(() => {
        this.loadingError$.next(true);
        return EMPTY;
      }),
    );
  }

  public onSubmit(): void {
    const formData = new FormData();
    const filesToUpload = this.fileList.filter(file => !file.currentlyUploaded);

    if (filesToUpload.length) {
      for (const file of filesToUpload) {
        formData.append('files', file as any);
      }
    }

    const body = {
      ...this.reportForm.value,
      filesIdsToRemove: this.filesIdsToRemove.filter(Number.isInteger),
      teacherId: this.authService.user?.teacherId || this.teacherId || this.scheduleCalendarService.activeTeacher?.value?.id,
      studentId: this.studentId,
      type: this.firstLessonId ? ReportType.DIAGNOSTIC : ReportType.REGULAR,
    };

    if (this.editMode) {
      body.type = this.reportForm.value.type;
    }

    formData.append('body', JSON.stringify(body));

    this.isLoading = true;

    let request: Observable<void>;

    if (this.firstLessonId) {
      request = this.scheduleApiService.conductFirstLesson(this.firstLessonId, formData);
    } else if (this.editMode && this.reportId) {
      request = this.scheduleApiService.editReport(this.reportId, formData);
    } else {
      request = this.scheduleApiService.createReport(formData);
    }

    request
      .pipe(finalize(() => { this.isLoading = false; }))
      .subscribe(() => {
        this.onSuccess.emit();
      });
  }

  get links(): UntypedFormArray {
    return this.reportForm.get('links') as UntypedFormArray;
  }

  public showLinkInput(): void {
    this.linkInputVisible = true;
    setTimeout(() => {
      this.linkInputElement?.nativeElement?.focus();
    }, 10);
  }

  public handleLinkInputConfirm(): void {
    if (this.linkInputValue.length > 255) {
      this.linkInputValue = '';
      this.linkInputVisible = false;
      return;
    }

    if (this.linkInputValue && this.links.controls.map(el => el.value).indexOf(this.linkInputValue) === -1) {
      this.links.push(new UntypedFormControl(this.linkInputValue));
    }

    this.linkInputValue = '';
    this.linkInputVisible = false;
  }

  public onRemoveLink(index): void {
    this.links.removeAt(index);
  }

  public sliceLinkValue(link: string): string {
    const isLongLink = link.length > 15;
    return isLongLink ? `${link.slice(0, 15)}...` : link;
  }

  public handleUploadImageChange(change: NzUploadChangeParam): void {
    const { file } = change;

    if (file.status === 'removed' && file.currentlyUploaded) {
      this.filesIdsToRemove.push(file.id);
    }
  }

  public beforeImageUpload = (file: NzUploadFile): boolean => {
    if (this.fileList.length >= 5) {
      return false;
    }

    if (!this.supportedFileTypes.includes(file.type)) {
      this.notificationService.showInfoMessage(this.translateService.instant('calendar.report.upload-err-by-type'));
      return false;
    }

    if (file.size > (this.oneKb * 512)) {
      this.notificationService.showInfoMessage(
        this.translateService.instant('calendar.report.upload-err-by-size', { size: Math.ceil(file.size / 1000) }),
      );
      return false;
    }

    const reader = new FileReader();

    reader.onload = event => {
      file.url = `${event.target.result}`;
      this.fileList = this.fileList.concat(file);

      setTimeout(() => {
        this.fileList = [...this.fileList]
      }, 0);
    };

    reader.readAsDataURL(file as any);

    return false;
  }

  public handleImageRemove = (file: NzUploadFile): boolean => {
    this.filesIdsToRemove.push(file.id);
    return true;
  }

  public handleImagePreview = async (file: NzUploadFile): Promise<void> => {
    this.imagePreview = file.url;
    this.isImagePreviewVisible = true;
  };

  public formatDate(date: string): string {
    const formatter = Intl.DateTimeFormat(this.languageService.locale, {
      month: 'long',
      day: 'numeric',
      year: 'numeric',
    });

    return formatter.format(new Date(date.split('T')[0]));
  }
}
