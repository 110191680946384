export enum SkipLessonReason {
  STUDENT_NO_REASON = 'student_no_reason',
  STUDENT_BY_REASON = 'student_by_some_reason',
  TEACHER_BY_REASON = 'teacher_by_some_reason',
  OTHER = 'other',
}

export interface SkipLessonBodyDto {
  sendNotification?: boolean;
  reason?: SkipLessonReason;
  reasonDescription?: string;
  force?: boolean;
}

export interface SkipLessonResponseDto {
  skipsCauseStudentNoReason?: number;
  conductsCauseStudentNoReason?: number;
}
