<div class="skip-lesson-modal-wrapper">
  <div class="loading-overlay" *ngIf="isLoading">
    <nz-spin [nzIndicator]="indicatorTemplate" nzSimple></nz-spin>
    <ng-template #indicatorTemplate><span style="font-size: 36px; color: #613CB0;" nz-icon nzType="loading"></span></ng-template>
  </div>
  <header>
    <div>{{'calendar.skip-lesson.header' | translate}}</div>
    <span nz-icon nzType="close" (click)="modalRef.close()"></span>
  </header>
  <body>
    <span>{{'calendar.skip-lesson.message' | translate}}</span>
    <div class="reason-select" *ngIf="authService.isTeacher()">
      <nz-select
        nzAllowClear
        [(ngModel)]="activeSkipReason"
        [nzPlaceHolder]="'calendar.skip-lesson.select-reason' | translate"
      >
        <nz-option
          *ngFor="let reason of skipReasons"
          [nzValue]="reason.value"
          [nzDisabled]="reason.disabled"
          [nzLabel]="('calendar.skip-lesson.reason.' + reason.value) | translate"
        ></nz-option>
      </nz-select>
      <textarea
        *ngIf="activeSkipReason === skipReason.OTHER"
        nz-input
        [placeholder]="'calendar.skip-lesson.custom-reason-ph' | translate"
        nzAutosize
        maxlength="255"
        [(ngModel)]="customSkipReason"
      ></textarea>
      <div class="info-wrap">
        <mathema-info-block [_type]="'info'" [text]="'calendar.skip-lesson.student_no_reason_enable_info' | translate"></mathema-info-block>
      </div>
    </div>
  </body>
  <footer>
    <button nz-button class="decline" (click)="modalRef.close()">{{'main.btn.decline' | translate}}</button>
    <label nz-checkbox *ngIf="data.showNotification" [(ngModel)]="notificationNeeded" class="notification">
      {{'calendar.lesson-transfer.notification' | translate}}
    </label>
    <button
      nz-button
      class="accept"
      (click)="submit()"
      [disabled]="isAcceptDisabled()"
    >{{'main.btn.accept' | translate}}</button>
  </footer>
</div>
