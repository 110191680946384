<div class="conduct-lesson-modal">
  <div class="wrapper" *ngIf="lessonData$ | async as lesson; else loadingOrError">
    <div class="conduct-lesson-loader" *ngIf="isLoading">
      <nz-spin [nzIndicator]="indicatorTemplate" nzSimple></nz-spin>
      <ng-template #indicatorTemplate><span style="font-size: 36px; color: #613CB0;" nz-icon nzType="loading"></span></ng-template>
    </div>

    <div class="info-block">
      <div class="title">{{'calendar.conduct-lesson.date-time' | translate}}</div>
      <div class="content date">{{formatDate(lesson.date)}}<span class="time">{{timeSlotToTime(lesson.timeSlot)}}</span></div>
    </div>
    <form nz-form [formGroup]="lessonForm" nzLayout="vertical">
      <nz-form-item>
        <nz-form-label [nzRequired]="!isSimplifiedValidation()" nzFor="subject">{{'calendar.conduct-lesson.subject' | translate}}</nz-form-label>
        <nz-form-control [nzErrorTip]="errorTplSubject">
          <input nz-input formControlName="subject" [placeholder]="'calendar.conduct-lesson.subject' | translate" />
        </nz-form-control>
        <ng-template #errorTplSubject let-control>
          <ng-container *ngIf="control.errors?.['required']">{{'main.form.errors.required-tip' | translate}}</ng-container>
          <ng-container *ngIf="control.errors?.['maxlength']">{{'main.form.errors.max-length-255' | translate}}</ng-container>
        </ng-template>
      </nz-form-item>
      <nz-form-item>
        <nz-form-label [nzRequired]="!isSimplifiedValidation()" nzFor="homework">{{'calendar.conduct-lesson.homework' | translate}}</nz-form-label>
        <nz-form-control [nzErrorTip]="'main.form.errors.required-tip' | translate">
           <textarea
             rows="2"
             formControlName="homework"
             nz-input
             [placeholder]="'calendar.conduct-lesson.homework' | translate"
             nzAutosize maxlength="2048"
           ></textarea>
        </nz-form-control>
      </nz-form-item>
      <nz-form-item>
        <nz-form-label nzFor="notes">{{'calendar.conduct-lesson.notes' | translate}}</nz-form-label>
        <nz-form-control>
          <textarea
            rows="2"
            formControlName="notes"
            nz-input
            [placeholder]="'calendar.conduct-lesson.notes' | translate"
            nzAutosize
            maxlength="2048"
          ></textarea>
        </nz-form-control>
      </nz-form-item>
      <nz-form-item>
        <nz-form-label nzFor="links">{{'calendar.conduct-lesson.links' | translate}}</nz-form-label>
        <nz-form-control class="links">
          <nz-tag
            *ngFor="let link of links.controls; let index = index"
            nzMode="closeable"
            (nzOnClose)="onRemoveLink(index)"
            nz-tooltip
            [nzTooltipTitle]="link.value"
          >
            <a [href]="link.value" target="_blank">{{ sliceLinkValue(link.value) }}</a>
          </nz-tag>
          <ng-container *ngIf="links.controls.length < 5">
            <ng-container *ngIf="!linkInputVisible; else linkInput">
              <nz-tag class="editable-tag" nzNoAnimation (click)="showLinkInput()">
                <span nz-icon nzType="plus"></span>
                {{'calendar.conduct-lesson.new-link' | translate}}
              </nz-tag>
            </ng-container>
            <ng-template #linkInput>
              <input
                #linkInputElement
                nz-input
                nzSize="small"
                type="text"
                [(ngModel)]="linkInputValue"
                [ngModelOptions]="{standalone: true}"
                style="width: 80px;"
                (blur)="handleLinkInputConfirm()"
                (keydown.enter)="handleLinkInputConfirm()"
              />
            </ng-template>
          </ng-container>
        </nz-form-control>
      </nz-form-item>
      <div style="margin-bottom: 16px">
        <mathema-info-block [_type]="'info'" [text]="'calendar.conduct-lesson.homework-materials-tip' | translate"></mathema-info-block>
      </div>
      <nz-form-item>
        <nz-form-label nzFor="materials" *ngIf="fileList.length">{{'calendar.conduct-lesson.materials' | translate}}</nz-form-label>
        <nz-form-control>
          <div class="materials" *ngIf="fileList.length">
            <nz-upload
              [(nzFileList)]="fileList"
              [nzShowButton]="false"
              nzListType="picture-card"
              [nzPreview]="handleImagePreview"
              [nzRemove]="handleImageRemove"
            >
            </nz-upload>
          </div>
          <nz-upload
            [nzBeforeUpload]="beforeImageUpload"
            [nzPreview]="handleImagePreview"
            [nzShowButton]="fileList.length < 5"
            (nzChange)="handleUploadImageChange($event)"
            [nzMultiple]="true"
            [nzLimit]="5"
          >
            <div class="upload-controls">
              <button nz-button class="upload-button">{{'calendar.conduct-lesson.upload' | translate}}</button>
              <div class="upload-info">{{'calendar.conduct-lesson.upload-tip' | translate}}</div>
            </div>
          </nz-upload>
          <nz-modal
            [nzVisible]="isImagePreviewVisible"
            [nzContent]="modalContent"
            [nzFooter]="null"
            (nzOnCancel)="isImagePreviewVisible = false"
          >
            <ng-template #modalContent>
              <img alt="" [src]="imagePreview" [ngStyle]="{ width: '100%' }" />
            </ng-template>
          </nz-modal>
        </nz-form-control>
      </nz-form-item>
    </form>
    <div class="action-buttons">
      <button class="cancel" (click)="onCancel.emit()">{{'main.btn.cancel' | translate}}</button>
      <button class="accept" [disabled]="lessonForm.invalid" (click)="onSubmit()">
        {{(editMode ? 'main.btn.save' : 'calendar.conduct-lesson.accept') | translate}}
      </button>
    </div>
  </div>
</div>

<ng-template #loadingOrError>
  <div class="error">
    <mathema-some-loading-error *ngIf="loadingError$ | async; else loading"></mathema-some-loading-error>
  </div>
  <ng-template #loading>
    <nz-spin [nzIndicator]="indicatorTemplate" nzSimple class="conduct-lesson-modal-loader"></nz-spin>
    <ng-template #indicatorTemplate><span style="font-size: 36px; color: #613CB0;" nz-icon nzType="loading"></span></ng-template>
  </ng-template>
</ng-template>
